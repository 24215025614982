import React from 'react';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import '@react-page/editor/lib/index.css';
import '@react-page/plugins-background/lib/index.css';
import '@react-page/plugins-html5-video/lib/index.css';
import '@react-page/plugins-spacer/lib/index.css';
import '@react-page/plugins-video/lib/index.css';
import '@react-page/plugins-image/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@migeran/plugins-form-state/lib/index.css';
import 'katex/dist/katex.min.css';
import type { AppProps } from 'next/app';
import '../styles/styles.css';

import createEmotionCache from '../utils/createEmotionCache';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const clientSideEmotionCache = createEmotionCache();
const theme = createTheme();
function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppProps & { emotionCache: EmotionCache }) {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
